.container {
  margin-top: 40px;

  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;

  > h1 {
    color: #fff;
  }

  .fieldProjetos {
    width: 100%;
    max-width: 800px;

    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-y: scroll;
    height: calc(100vh - 300px);

    padding: 20px;

    gap: 3rem;

    &::-webkit-scrollbar {
      width: 20px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e69138;
      border-radius: 10px;
    }
  }

  .subTitle {
    font-size: 50px;
    letter-spacing: 10px;

    cursor: default;

    bottom: 40px;

    color: #e69138;

    text-decoration: underline;

    font-weight: 400;

    font-family: "Amatic SC", sans-serif;
  }
}