.container {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  position: relative;

  height: calc(100vh - 80px);

  .mainTitle {
    padding: 0 300px;

    color: #e69138;

    text-align: center;
    font-size: 50px;
    font-weight: 600;
  }

  span {
    position: absolute;
    bottom: 40px;
  }

  .subTitle {
    font-size: 100px;
    letter-spacing: 10px;

    cursor: default;

    color: #e69138;

    text-decoration: underline;

    font-weight: 400;

    font-family: "Amatic SC", sans-serif;
  }
}