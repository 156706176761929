html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;

  /* background: var(--gray-100); */

  /* background-image: url(https://i.pinimg.com/originals/90/43/a7/9043a730ef9d90e2b2e6c92265939457.jpg); */
}

body {
  margin-top: 80px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;

  margin: 0;
}

.p-menu {
  width: 14rem !important;

  background-color: rgb(230, 145, 56) !important;
}

.p-menuitem-text {
  color: #000 !important;
}

.p-menu .p-submenu-header {
  padding: 0 !important;
}
