.container {
  display: flex;
  justify-content: center;

  .subContainer {
    width: 100%;
    max-width: 1220px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 20px;

    margin-top: 40px;

    padding: 40px;

    h1 {
      text-align: center;
      color: #fff;
      width: 100%;
    }

    .fieldDoc {
      // overflow-y: scroll;
      width: 100%;
      max-width: 800px;

      margin-top: 20px;

      padding: 20px;

      display: flex;
      flex-direction: column;

      gap: 1rem;

      // img {
      //   border-radius: 5px;

      //   width: 100%;
      //   height: max-content;
      //   max-width: calc(100% - 40px);
  
      //   box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      // }

      .fieldArquivoItem {
        display: flex;
        align-items: center;

        cursor: pointer;

        gap: .5rem;

        i {
          color: #e69138;
          font-size: 25px;
        }

        p {
          color: #fff;
          font-size: 25px;
        }

        &:hover {
          p {
            text-decoration: underline;
          }
        }
      }
    }

    img {
      width: 60px;
    }

    span {
      position: absolute;
      bottom: 40px;
    }

    .subTitle {
      font-size: 50px;
      letter-spacing: 10px;

      cursor: default;
    
      color: #e69138;
  
      text-decoration: underline;
  
      font-weight: 400;
  
      font-family: "Amatic SC", sans-serif;
    }
  }
}