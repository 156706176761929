.container {
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: flex;

  justify-content: space-between;

  background: url(https://ssl.gstatic.com/atari/images/vision-header.jpg);

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  top: 0;

  z-index: -1;

  filter: blur(4px);

  section {
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.7);
  }
}