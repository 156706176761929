.container {
	width: 100%;
  height: 80px;

	padding: 0 40px;

	background: #b5722b;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	display: flex;
	justify-content: center;

	position: fixed;

	top: 0;

	.subContainer {
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.leftHeader {
		cursor: pointer;
	}

	img {
		width: 50px;
		height: 50px;
	}

	h1 {
		color: #000;

		font-size: 50px;

		font-family: "Amatic SC", sans-serif;

		letter-spacing: 10px;
	}

	.headerNavigator {
		display: flex;

		gap: 3rem;

		a, p {
			display: flex;
			align-items: center;

			color: #000;
			cursor: pointer;

			transition: .3s ease;

			&:hover {
				color: var(--gray-100);
				// text-decoration: underline;
			}
		}
	}

	div.leftBar {
    position: absolute;

    left: 0;
    width: 20px;
    height: 100vh;

    background-color: #b5722b;
  }

  div.rightBar {
    position: absolute;

    right: 0;
    width: 20px;
    height: 100vh;

    background-color: #b5722b;
  }
}