.container {
  width: 100%;

  border-radius: 5px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: rgba(0, 0, 0, 0.2);

  padding: 20px;

  h1 {
    color: #e69138;
    padding-left: 10px;
    font-size: 20px;
  }

  .fieldButtons {
    width: 100%;

    display: flex;
    gap: 1rem;

    margin-top: 20px;

    a, button {
      height: 80px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      text-decoration: none;

      background: transparent;

      border-radius: 5px;
      border: 1px solid #e69138;
      cursor: pointer;

      transition: .3s ease;

      font-size: 25px;
      font-weight: 600;

      background: #e69138;
      color: #fff;

      &:hover {
        transform: scale(1.02);
        filter: brightness(.9);
      }
    }
  }
}

.praticaPdfField {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1rem;

  min-width: 350px;
  width: 100%;

  .praticaPdfButton {
    width: 100%;
    height: 50px;

    background: #e69138;
    color: #fff;

    border: none;
    border-radius: 4px;

    transition: .2s ease;
    cursor: pointer;

    &:hover {
      filter: brightness(0.95);
	    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }
}
