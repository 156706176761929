.container {
  display: flex;
  justify-content: center;

  .subContainer {
    width: 100%;
    max-width: 1220px;

    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 20px;

    margin-top: 40px;

    padding: 40px;

    h1 {
      text-align: center;
      color: #fff;
      width: 100%;
    }

    .fieldDoc {
      overflow-y: scroll;
      height: calc(100vh - 300px);
      width: 100%;

      padding: 20px;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &::-webkit-scrollbar {
        width: 20px;
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #e69138;
        border-radius: 10px;
      }

      div {
        height: fit-content;
        padding: 1rem;
        margin-bottom: 1rem;
        border-radius: 5px;

        background: rgba(0, 0, 0, 0.2);

        cursor: pointer;

        transition: .3s ease;

        &:hover {
          transform: scale(1.02);
          filter: brightness(0.9);
        }

        h2 {
          color: #e69138;
          margin-bottom: 1rem;
        }
  
        img {
          border: 1px solid #e69138;

          width: 480px;
          height: 300px;
        }
      }
    }

    span {
      position: absolute;
      bottom: 40px;
    }

    .subTitle {
      font-size: 50px;
      letter-spacing: 10px;
  
      cursor: default;
  
      color: #e69138;
  
      text-decoration: underline;
  
      font-weight: 400;
  
      font-family: "Amatic SC", sans-serif;
    }
  }
}